import institutions from "./institutions";
import applyInstitutions from "./applyInstitutions";
import institutionsEmployee from "./institutionsEmployee";
import institutionsStore from "./institutionsStore";
import diyService from "./diyService";
import serviceItems from "./serviceItems";
import serviceTeam from "./serviceTeam";
import honoraryQualifications from "./honoraryQualifications";
import admissionCases from "./admissionCases";
import userEvaluate from "./userEvaluate";
import strategyInfo from "./strategyInfo";
import institutionsUserEvaluate from "./institutionsUserEvaluate";
import storeManage from "./storeManage";
import enrollmentProgram from "./enrollmentProgram";
import honoraryQualificationsSchool from "./honoraryQualificationsSchool";
import comment from "./comment";
export default [
  institutions,
  applyInstitutions,
  institutionsEmployee,
  institutionsStore,
  diyService,
  serviceItems,
  serviceTeam,
  honoraryQualifications,
  admissionCases,
  userEvaluate,
  strategyInfo,
  institutionsUserEvaluate,
  storeManage,
  enrollmentProgram,
  honoraryQualificationsSchool,
  comment
];
