import { platform, conversionTarget } from '@/utils/public';
export default {
  key: '/registeredUsers/platformData',
  title: '平台数据',
  type: 'table',
  permission: '/clientUser/statistics/getList',
  config: {
    dataUrl: '/clientUser/statistics/getList',
    filter: {
      controls: [
        {
          key: 'time',
          label: '时间',
          type: 'dateRange',
          resetDisabled: true,
          config: {
            initialValue: '当月',
          }
        },
        {
          key: 'eventType',
          label: '客资转化',
          type: 'souSelect',
          config: {
            options: conversionTarget
          }
        },
      ]
    },

    /* 表格操作 */
    actions: [
      
    ],

    columns: [
      {
        key: 'platform',
        width: '150px',
        title: '平台',
      },
      {
        key: 'visitNum',
        width: '150px',
        title: '访客',
        popover: '网站：UV APP/小程序：活跃用户',
      },
      {
        key: 'newVisitNum',
        width: '150px',
        title: '新访客',
      },
      {
        key: 'registerNum',
        width: '100px',
        title: '注册数 / 转化率',
        type: 'textCustomCol',
        popover: '新访客到注册的转化率',
        config: {
          text: (val) => {
            let text = '';
            if(val.newVisitNum == 0) {
              text = '暂无'
            } else {
              text = `${(val.registerNum/val.newVisitNum*100).toFixed(2)}%`
            }
            return `${val.registerNum} / ${text}`
          }
        }
      },
      {
        key: 'transferNum',
        width: '100px',
        title: '客资转化数 / 转化率',
        type: 'textCustomCol',
        popover: '访客到留资的转化率',
        config: {
          text: (val) => {
            let text = '';
            if(val.visitNum == 0) {
              text = '暂无'
            } else {
              text = `${(val.transferNum/val.visitNum*100).toFixed(2)}%`
            }
            return `${val.transferNum} / ${text}`
          }
        }
      },
      {
        key: 'orgReservationNum',
        width: '100px',
        title: '预约转化数 / 转化率',
        type: 'textCustomCol',
        popover: '访客到预约的转化率',
        config: {
          text: (val) => {
            let text = '';
            if(val.visitNum == 0) {
              text = '暂无'
            } else {
              text = `${(val.orgReservationNum/val.visitNum*100).toFixed(2)}%`
            }
            return `${val.orgReservationNum} / ${text}`
          }
        }
      },
      {
        key: 'signedNum',
        width: '100px',
        title: '签单数 / 转化率',
        type: 'textCustomCol',
        popover: '转化总数到签单的转化率',
        config: {
          text: (val) => {
            let total = parseInt(val.transferNum) + parseInt(val.orgReservationNum);
            let text = '';
            if(total == 0) {
              text = '暂无'
            } else {
              text = `${(val.signedNum/total*100).toFixed(2)}%`
            }
            return `${val.signedNum} / ${text}`
          }
        }
      },
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['time'],
      /* 默认排序 */
      defaultSort: '-time'
    },

    /* 表格行操作 */
    rowActions: [
    ]
  }
};
