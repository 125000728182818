<template>
  <div>
    <!-- 统计数据 -->
    <div v-if="config.otherInfos && attachData">
      <div v-html="config.otherInfos.renderHtml(attachData)"></div>
    </div>
    <!-- 过滤 -->
    <div class="page-filter">
      <a-form ref="filterForm" :model="filterForm" layout="inline">
        <a-form-item v-for="control in filterControls" :key="control.key" :label="control.label" :name="control.key">
          <!-- 文本 -->
          <template v-if="control.type === 'text'">
            <a-input class="page-btm" :style="{ width: control.width + 'px' }"
              v-model:value.trim="filterForm[control.key]" :placeholder="`请输入${control.label}`" allow-clear />

          </template>
          <!-- 日期范围 -->
          <template v-else-if="control.type === 'dateRange'">
            <a-range-picker :popupClassName="$isMobile ? 'rangStyle' : ''" class="page-btm"
              :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]"
              :placeholder="['开始时间', '结束时间']" />
          </template>

          <!-- 日期 -->
          <template v-else-if="control.type === 'date'">
            <a-date-picker class="page-btm" :disabled-date="control.disabledDate ? disabledDate : () => { }"
              :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]" placeholder="全部"
              :picker="control.config.picker" />
          </template>

          <!-- 日期时间 -->
          <template v-else-if="control.type === 'datetime'">
            <a-date-picker class="page-btm" :style="{ width: control.width + 'px' }" show-time
              v-model:value="filterForm[control.key]" placeholder="全部" />
          </template>

          <!-- 数值 -->
          <template v-else-if="control.type === 'numeric'">
            <a-input-number class="page-btm" :style="{ width: control.width + 'px' }"
              v-model:value="filterForm[control.key]" placeholder="全部" />
          </template>

          <!-- 下拉选择 -->
          <template v-else-if="control.type === 'select'">
            <a-select :key="control.config.options" class="page-btm" :style="{ width: control.width + 'px' }"
              v-model:value="filterForm[control.key]" placeholder="全部" allow-clear>
              <a-select-option v-for="option in control.config.options" :key="option.value">{{
        option.text
      }}
              </a-select-option>
            </a-select>
          </template>
          <!-- 下拉搜索选择 -->
          <template v-else-if="control.type === 'souSelect'">
            <a-select class="page-btm" :filter-option="filterSouOption" show-search
              :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]" placeholder="全部"
              allow-clear>
              <a-select-option v-for="option in control.config.options" :key="option.value" :text="option.text">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </template>
          <!-- 下拉多选选择 -->
          <template v-else-if="control.type === 'mSelect'">
            <a-select class="page-btm" :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]"
              mode="multiple" allow-clear show-search :placeholder="control.label" :filter-option="filterOption">
              <a-select-option v-for="option in control.config.options" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{
        option.name }}
              </a-select-option>
            </a-select>
          </template>

          <!-- 远程下拉选择 -->
          <!-- <template v-else-if="control.type === 'remoteSelect'">
            <a-select class="page-btm" show-search :value="value" placeholder="全部" style="width: 200px"
              :default-active-first-option="false" :show-arrow="false" :not-found-content="null" @search="handleSearch"
              @change="handleChange" :filterOption="filterOption">
              <a-select-option v-for="d in data" :key="d.value">
                {{ d.text }}
              </a-select-option>
            </a-select>
          </template> -->
          <!-- 树状下拉选择 -->
          <template v-else-if="control.type === 'treeSelect'">
            <a-tree-select v-if="control.config.options" class="page-btm" v-model:value="filterForm[control.key]"
              style="width: 180px" allow-clear :tree-data="control.config.options" tree-default-expand-all
              placeholder="全部">
            </a-tree-select>
            <a-tree-select v-if="control.config.list" class="page-btm" v-model:value="filterForm[control.key]"
              style="width: 180px" allow-clear :tree-data="control.config.list" tree-default-expand-all
              placeholder="全部">
            </a-tree-select>
          </template>
          <!-- 级联选择 -->
          <template v-else-if="control.type === 'cascader'">
            <a-cascader class="page-btm" v-model:value="filterForm[control.key]" change-on-select
              :show-search="{ filter }" :options="control.config.options" placeholder="全部" />
          </template>
          <!-- 数字范围 -->
          <template v-else-if="control.type === 'numberRange'">
            <a-input-number class="page-btm" style="width: 80px" v-model:value="filterForm[control.start]"
              placeholder="最小值" />
            <span style="margin: 0 10px">-</span>
            <a-input-number class="page-btm" style="width: 80px" v-model:value="filterForm[control.end]"
              placeholder="最大值" />
          </template>



        </a-form-item>
        <a-form-item v-if="filterControls.length !== 0">
          <a-button type="primary" html-type="submit" :loading="filterSubmitLoading" @click="handleFilterSubmit">
            <template #icon>
              <SearchOutlined />
            </template>
            查询</a-button>
        </a-form-item>
        <a-form-item v-if="filterControls.length !== 0" @click="resetFilterSubmit">
          <a-button>重置</a-button>
        </a-form-item>
      </a-form>
    </div>

    <!-- 操作 -->
    <div style="display: flex; align-items: center; flex-wrap: wrap; margin-top: 10px;">
      <div v-if="config.belongTo" style="margin-right: 30px;">{{ config.belongTo }}</div>
      <div class="page-actions">
        <!-- <a-space> -->
        <template v-for="action in noPermissionActions" :key="action.key">
          <component v-if="getActionComponent(action) === 'dialog-action'" :key="showId"
            style="margin-right: 20px; margin-bottom: 10px;" :is="getActionComponent(action)" type="button"
            :action="action" :filterForm="filterForm" @change="change" :selected-rows="selectedRows"
            :tableData="tableData" :showId="showId" />
          <component v-else style="margin-right: 20px; margin-bottom: 10px;" :is="getActionComponent(action)"
            type="button" :action="action" :filterForm="filterForm" @change="change" :selected-rows="selectedRows"
            :tableData="tableData" :showId="showId" />
        </template>

        <!-- </a-space> -->
      </div>
    </div>

    <!-- 统计筛选 -->
    <div style="margin-top: 10px;" v-if="config.listFilter">
      <a-radio-group v-model:value="listFilterKey[config.listFilter.key]" @change="statisticsChange">
        <a-radio-button v-for="(item, index) in config.listFilter.options" :value="item.value">
          <span>{{ item.name }}</span>
          <a-popover v-if="item.tips" placement="top">
            <template #content>
              <p>{{ item.tips }}</p>
            </template>
            <question-circle-outlined class="ls-icon" />
          </a-popover>
        </a-radio-button>
      </a-radio-group>
    </div>

    <!-- 表格 -->
    <div class="page-table">
      <a-table :sticky="config.sticky" :scroll="config.scroll" :columns="tableColumns" :data-source="tableData"
        :loading="tableLoading" size="small" bordered
        :row-selection="config.checkbox ? { selectedRowKeys: tableSelectedRowKeys, onChange: handleTableRowSelect } : null"
        :rowKey="record => record.id" :pagination="config.pagination === false ? false : tablePagination"
        @change="handleTableChange">
        <template #summary v-if="config.summary">
          <a-table-summary>
            <a-table-summary-row style="background-color: #fffff1;">
              <a-table-summary-cell :index="0"> 合计</a-table-summary-cell>
              <a-table-summary-cell v-for="(item, index) in tableNoOneColumns" :key="index" :index="1">
                <template v-if="item.summary">
                  <template v-if="item.type === 'numeric' || item.type === 'modal'">
                    <template v-if="item.config.format === 'percent' || item.config.format === 'currency'">
                      {{ $formatCurrency(combinedNums(item.key)) }}
                    </template>
                    <template v-else> {{ combinedNums(item.key) }}</template>

                  </template>
                  <template v-else>
                    {{ combinedNums(item.key) }}
                  </template>
                </template>
                <template v-else>
                  {{ }}
                </template>
              </a-table-summary-cell>
            </a-table-summary-row>
          </a-table-summary>
        </template>
        <!-- title加入popover提示 -->
        <template #headerCell="{ column }">
          <div class="resizable-header" :style="{ resize: column.resize ? 'horizontal' : 'none' }">
            <template v-if="column.popover">
              <span>{{ column.title }}</span>
              <a-popover title="">
                <template #content>
                  <p>{{ column.popover }}</p>
                </template>
                <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
              </a-popover>
            </template>
            <template v-else>
              <span>{{ column.title }}</span>
            </template>
            <div class="resize-handle"></div>
          </div>
        </template>
        <template #bodyCell="{ column, record }">
          <!-- 文本 -->
          <template v-if="column.type === 'text'">
            <span v-if="!column.ellipsis">
              {{ getArrSpliComma(column.key, record) }}
            </span>
            <a-tooltip v-else placement="topLeft" :title="getArrSpliComma(column.key, record)">
              <span>{{ getArrSpliComma(column.key, record) }}</span>
            </a-tooltip>
          </template>

          <!-- a-popover -->
          <template v-if="column.type === 'popover'">
            <a-tooltip placement="topLeft" :title="getArrSpliComma(column.key, record)" arrow-point-at-center>
              <p class="ellipsis">{{ getArrSpliComma(column.key, record) }}</p>
            </a-tooltip>
          </template>

          <!-- 城市 只要中间 市-->
          <template v-if="column.type === 'splitCity'">
            <span>
              {{ getArrSpliComma(column.key, record).split(',')[1] }}
            </span>
          </template>
          <template v-if="column.type === 'textList'">
            {{ getArrStrComma(column.ckey, record[column.key]) }}
          </template>
          <!-- 图片 -->
          <template v-if="column.type === 'image'">
            <a-image style="max-width:50px;max-height:50px" :src="record[column.key]" />
          </template>
          <!-- 数值 -->
          <template v-else-if="column.type === 'numeric'">
            <span v-if="getArrSpliComma(column.key, record) != null">
              <template v-if="column.config.format === 'percent'">
                {{ $formatCurrency(getArrSpliComma(column.key, record)) }}
              </template>
              <template v-else-if="column.config.format === 'currency'">
                {{ $formatCurrency(getArrSpliComma(column.key, record)) }}
              </template>
              <template v-else>{{ getArrSpliComma(column.key, record) }}</template>
            </span>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 日期时间 -->
          <template v-else-if="column.type === 'date'">
            <span>{{ getYMDHMSMoment(getArrSpliComma(column.key, record)) }}</span>
          </template>
          <!-- 日期 -->
          <template v-else-if="column.type === 'datetime'">
            <span>{{ getYMDMoment(getArrSpliComma(column.key, record)) }}</span>
          </template>
          <!-- 标签枚举 -->
          <template v-else-if="column.type === 'enum'">
            <span v-if="column.config != null">
              <a-tag style="margin: 0;"
                :color="column.config.constants[getArrSpliComma(column.key, record)] && column.config.constants[getArrSpliComma(column.key, record)].color">
                {{ column.config.constants[getArrSpliComma(column.key,
        record)] && column.config.constants[getArrSpliComma(column.key, record)].text }}</a-tag>
            </span>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 枚举 -->
          <template v-else-if="column.type === 'textEnum'">
            <div v-if="column.config != null">
              <span>{{ column.config.constants[record[column.key]] }}</span>
            </div>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 自定义 -->
          <template v-else-if="column.type === 'textCustom'">
            <div v-if="column.config != null">
              <span>{{ column.config.text(record[column.key]) }}</span>
            </div>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 自定义当前行 -->
          <template v-else-if="column.type === 'textCustomCol'">
            <div v-if="column.config != null">
              <span>{{ column.config.text(record) }}</span>
            </div>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 自定义html -->
          <template v-else-if="column.type === 'textHtml'">
            <div v-if="column.config != null">
              <p v-html="column.config.text(record)"></p>
            </div>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 可跳转 -->
          <template v-if="column.type === 'link'">
            <a-button @click="jump(column, record)" type="link">{{ column.config.text(record[column.key]) }}</a-button>
          </template>
          <!-- color -->
          <template v-else-if="column.type === 'color'">
            <span v-if="record[column.key] != null">
              <a-tag class="tag-color"
                :style="{ backgroundColor: bgColor(getArrSpliComma(column.config.colorkey, item)), border: borderColor(getArrSpliComma(column.config.colorkey, item)), color: getArrSpliComma(column.config.colorkey, item) }"
                v-for="(item, index) in record[column.key]" :color="getArrSpliComma(column.config.colorkey, item)">
                {{ getArrSpliComma(column.config.textkey, item) }}
              </a-tag>
            </span>
          </template>

          <!-- 图片查看 -->
          <template v-if="column.type === 'preview'">
            <a-button type="link" @click="() => visible = true">查看</a-button>
            <a-button type="link" disabled>查看</a-button>
          </template>

          <!-- 弹窗查看 -->
          <template v-if="column.type === 'modal'">
            <a-button style="padding: 0;" :disabled="column.disabled ? column.disabled(record) : false" type="link"
              @click="showInfo(record, column.key)">{{
        column.config.text(record)
      }}</a-button>
          </template>

          <!-- 操作 -->
          <template v-else-if="column.type === 'actions'">
            <template v-for="(action, index) in noPermissionRowActions">
              <!-- <a-divider
                v-if="index > 0 && noPermissionRowActions.findIndex(val => !val.disabled || (val.disabled && rowActionVisible(val, [record]))) !== index"
                type="vertical" /> -->
              <a-divider v-if="index > 0" type="vertical" />
              <template v-if="Array.isArray(action)">
                <a-dropdown>
                  <a class="ant-dropdown-link">更多
                    <down-outlined />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item v-for="childAction in action" :key="childAction.key">
                      <component v-if="!childAction.disabled" :is="getActionComponent(childAction)" type="link"
                        :action="childAction" :disabledValue="rowActionVisible(childAction, [record])"
                        :selected-rows="[record]" />
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </template>
              <component v-else :is="getActionComponent(action)" type="link" :action="action"
                :disabledValue="rowActionVisible(action, [record])" :selected-rows="[record]" @change="change" />
            </template>
          </template>
        </template>
      </a-table>
    </div>
    <div style="display: none">
      <a-image-preview-group :preview="{ visible, onVisibleChange: imageChange }">
        <a-image src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp" />
        <a-image src="https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp" />
        <a-image src="https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp" />
      </a-image-preview-group>
    </div>
    <a-modal :style="{ width: !$isMobile ? '800px' : '' }" v-model:open="openInfoModal" :title="modal.title"
      @cancel="closeInfoModal">
      <div style="padding: 30px 0;" v-if="modal.table">
        <a-table :dataSource="modal.data" :columns="modal.columns" :pagination="false">
          <template #bodyCell="{ column, record }">
            <template v-if="column.type === 'textCustom'">
              <div v-if="column.config != null">
                <span>{{ column.config.text(record[column.key]) }}</span>
              </div>
            </template>
            <template v-else-if="column.type === 'datetime'">
              <span>{{ getYMDMoment(getArrSpliComma(column.key, record)) }}</span>
            </template>
          </template>
        </a-table>
      </div>
      <div v-else v-html="modal.content" style="padding: 20px 0;"></div>
      <template #footer>
        <div style="text-align: center;">
          <a-button type="primary" @click="closeInfoModal">好的</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<style lang="scss" scoped>
:deep(.ant-table-summary) {
  display: table-header-group;

}

.ellipsis {
  width: 200px;
  overflow: hidden;
  /* 确保超出容器的文本被裁剪 */
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  text-overflow: ellipsis;
  /* 使用省略号表示文本超出 */
}

.page-table {
  margin-top: 12px;
  background: #fff;
}

.page-actions {
  margin-top: 12px;
}

.page-btm {
  margin-bottom: 10px;
}

:deep(.ant-table-content) {
  overflow-x: auto;
  width: 100%;
}

// 滚动条的宽度 ant-table-body
:deep(.ant-table-content::-webkit-scrollbar),
:deep(.ant-table-body::-webkit-scrollbar) {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.ant-table-content::-webkit-scrollbar-thumb),
:deep(.ant-table-body::-webkit-scrollbar-thumb) {
  background-color: #ddd;
  border-radius: 3px;
}

:deep(.ant-table-content::-webkit-scrollbar),
:deep(.ant-table-body::-webkit-scrollbar) {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #666666;
}
</style>
<style lang="scss">
.rangStyle {
  .ant-picker-panels {
    display: inline-flex;
    flex-wrap: nowrap;
    direction: ltr;
    flex-direction: column;
  }
}

.resizable-header {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  // border-right: 1px solid #ddd;  
  // resize: horizontal; /* 允许水平拖动调整大小 */
}
</style>
<script>

import FormAction from "@/components/FormAction";
import ConfirmAction from "@/components/ConfirmAction";
import BlankAction from "@/components/BlankAction";
import UrlAction from "@/components/UrlAction";
import DialogAction from "@/components/DialogAction";
import Desensitization from "@/components/list/Desensitization";
import tree from "@/utils/tree";
import dayjs from "dayjs";
import chroma from 'chroma-js';
import { getProfile } from "@/utils/session";
export default {
  name: "TablePage",
  components: { DialogAction, UrlAction, BlankAction, ConfirmAction, FormAction, Desensitization },
  props: {
    config: Object
  },
  data() {
    return {
      visible: false,
      noPermissionRowActions: [],
      noPermissionActions: [],
      filterForm: {},
      filterInitData: {},
      filterSubmitLoading: false,
      tableLoading: false,
      tableData: [],
      attachData: null,
      tableRowKey: "id",
      tableSelectedRowKeys: [],
      tableSort: this.config.sorter?.defaultSort,
      tablePagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: total => `共 ${total} 条`,
        showSizeChanger: true, // 选择每页条数开关
        pageSizeOptions: ['10', '20', '50', '100'],
      },
      showId: null,
      listFilterKey: {},
      // data: [],
      // value: undefined,
      openInfoModal: false,
      modal: {
        title: '',
        content: '',
        table: false,
        data: [],
        url: '',
        needForm: false,
        filter: '',
        row: {},
        columns: []
      }
    };
  },

  created() {

  },

  computed: {
    /**
     * 下拉选择数据
     */
    treeSelectData() {
      if (this.filterInitData == null) {
        return {};
      }
      return this.filterControls.filter(({ type }) => type === 'treeSelect').reduce((prev, control) => {
        prev[control.key] = tree(this.filterInitData[control.config.options]);
        return prev;
      });
    },

    /**
     * 过滤表单项
     */
    filterControls() {
      return this.config.filter.controls.map(control => {
        if (control.type === 'select' || control.type === 'souSelect') {
          return {
            ...control, width: 130,
            config: {
              ...control.config,
              options: typeof control.config.options === 'string' ? (this.filterInitData[control.config.options] ?? []).map(item => ({
                text: item[control.config.textKey ?? 'name'],
                value: item[control.config.valueKey ?? 'id']
              })) :
                control.config.options?.map(option => ({
                  ...option,
                  value: (typeof option.value === 'boolean') ? String(option.value) : option.value
                }))
            }
          }
        } else if (control.type === 'mSelect') {
          return {
            ...control, width: 130,
            config: {
              ...control.config,
              options: typeof control.config.options === 'string' ? (this.filterInitData[control.config.options] ?? []).map(item => ({
                id: item[control.config.textKey ?? 'id'],
                name: item[control.config.valueKey ?? 'name']
              })) :
                control.config.options?.map(option => ({
                  ...option,
                  name: (typeof option.value === 'boolean') ? String(option.name) : option.name
                }))
            }
          }
        }
        else if (control.type === 'treeSelect') {
          return {
            ...control, width: 100,
            config: {
              options: typeof control.config.options === 'string'
                ? tree(this.filterInitData[control.config.options] ?? [], {
                  transform(node) {
                    const value = node[control.config.valueKey ?? 'id'];
                    return {
                      title: node[control.config.textKey ?? 'name'],
                      value,
                      key: value
                    };
                  }
                }) : control.config.options,
              list: this.filterInitData[control.config.list]
            }
          }
        } else if (control.type === 'dateRange') {

          if (control.type === 'dateRange') {
            if (control.config && control.config.initialValue == '当月') {
              let today = dayjs().format('YYYY-MM-DD')
              let firstDay = today.split('-')[0] + '-' + today.split('-')[1] + '-01'
              firstDay = dayjs(firstDay).format('YYYY-MM-DD' + ' 00:00:00')
              today = dayjs(today).format('YYYY-MM-DD' + ' 23:59:59')
              let month = []
              month[1] = dayjs(today)
              month[0] = dayjs(firstDay)
              this.filterForm[control.key] = month
            }
          }

          return {
            ...control,
            width: 230
          }
        } else if (control.type === 'date') {
          if (control.config && control.config.initialValue == '上月') {
            if (!this.filterForm[control.key]) {
              const today = dayjs();
              const lastMonth = today.subtract(1, 'month');
              this.filterForm[control.key] = lastMonth
            }

          }
          return {
            ...control,
            width: 140
          }
        } else {
          return {
            ...control,
            width: 140
          }
        }
      });
    },

    /**
     * 表格列
     */
    tableColumns() {
      let columns = this.config.columns.map(column => {
        // if (column.columnsHidden && typeof column.columnsHidden === 'function') {
        //   column.colHidden = column.columnsHidden(this.isSpecialInstitutions)
        // }
        return column
      })
      let filterColumns;
      if (this.noPermissionRowActions.length == 0) {
        filterColumns = columns.filter(column => !column.colHidden && column.type !== 'actions');
      } else {
        filterColumns = columns.filter(column => !column.colHidden);
      }
      return filterColumns.map(column => {
        return {
          ...column,
          type: column.type ?? 'text',
          config: column.config ?? {}
        }
      });
      // return this.config.columns.map(column => {
      //   return {
      //     ...column,
      //     type: column.type ?? 'text',
      //     config: column.config ?? {}
      //   }
      // });
    },
    tableNoOneColumns() {
      let tableColumns = this.tableColumns
      let noOneColumns = tableColumns.slice(1)
      return noOneColumns
    },
    /**
     * 操作
     */
    actions() {
      return this.config.actions;
    },

    /**
     * 表格行操作
     */
    rowActions() {
      return this.config.rowActions ?? [];
    },

    /**
     * 表格行操作
     */
    tableActions() {
      return this.config.actions ?? [];
    },

    /**
     * 表格数据 Map
     */
    tableDataMap() {
      return this.tableData.reduce((prev, row) => {
        prev[row[this.tableRowKey]] = row;
        return prev;
      }, {});
    },

    /**
     * 选择的表格行
     */
    selectedRows() {
      return this.tableSelectedRowKeys.map(key => this.tableDataMap[key]);

    },

    /**
     * 可排序列
     */
    sortableColumns() {
      return new Set(this.config.sorter?.sortableColumns);
    }
  },
  mounted() {
    this.getProfile()

    if (this.config.listFilter) {
      if (this.$route.query.select) {
        this.listFilterKey[this.config.listFilter.key] = this.$route.query.select;
      } else {
        this.listFilterKey[this.config.listFilter.key] = this.config.listFilter.defaultValue;
      }
    }

    // if (this.config.filter.initUrl != null) {
    this.loadFilterInitData();
    // }
    this.loadTableData();
  },
  methods: {
    imageChange(e) {
      this.visible = e;
    },

    combinedNums(field) {
      return this.tableData.reduce((prev, next) => {
        return Number(prev) + Number(next[field])
      }, 0);
    },
    async getProfile() {
      try {
        let profile = await getProfile();
        // this.isSpecialInstitutions = profile.institutions.name;
        let actions = this.actions.filter(action => {
          if (profile.everyPermissions(action.permission)) {
            return action
          }
        });
        let rowActions = this.rowActions.filter(action => {
          if (profile.everyPermissions(action.permission)) {
            return action
          }
        }
        );
        this.noPermissionRowActions = rowActions
        this.noPermissionActions = actions
      } catch ({ message, status }) {
        if (status !== 401) {
          this.$message.error(message);
        }
      }
    },
    bgColor(color) {
      return chroma(color).brighten(2).hex();
    },
    borderColor(color) {
      return chroma(color).brighten(-0.5).hex();
    },
    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },
    //=========================================
    // 将输入的内容与显示的内容进行匹配
    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },
    // handleSearch(value) {
    //   console.log(value, '444444444444444');
    //   fetch(value, data => (this.data = data));
    // },
    // handleChange(value) {
    //   console.log(value, '2222222222222');
    //   this.value = value;
    //   fetch(value, data => (this.data = data));
    // },

    filter(inputValue, path) {
      // console.log(inputValue, path);
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) > -1);
    },
    rowActionVisible(action, row) {
      if (action.disabled == null) {
        return true;
      }

      if (typeof action.disabled === 'function') {
        return !action.disabled({
          selectedRows: row
        });
      }

      // if (typeof action.subText === 'function') {
      //   return action.subText({
      //     selectedRows: row
      //   });
      // }
      // console.log(action.disabled);
      // return !action.disabled({
      //   selectedRows: row
      // });
    },
    /**
     * 确认完成
     */
    change(e) {
      this.loadTableData({
        resetPage: false
      });
      if (e) {
        this.showId = e;
      }
    },
    /**
     * 加载过滤初始数据
     */
    async loadFilterInitData() {
      if (this.config.filter.initUrl != null) {
        this.filterInitData = await this.$http.get(this.config.filter.initUrl);
      }
      if (this.config.filter.otherUrls && this.config.filter.otherUrls.length > 0) {
        for (let item of this.config.filter.otherUrls) {
          let res = await this.$http.get(item.url);
          this.filterInitData[item.nameKey] = res[item.nameKey];
          if (item.type == 'treeSelect') {
            this.filterInitData[item.nameKey].map(i => {
              i.label = i.optionKey;
              i.value = i.optionValue;
              if (i.childrenList != '' && i.childrenList != null) {
                i.selectable = false;
                let arr = i.childrenList.split(',')
                i.children = arr.map(it => ({
                  label: it,
                  value: it,
                }))
              }
            })
          }
        }
      }
    },

    /**
     * 处理过滤表单查询
     */
    async handleFilterSubmit() {
      this.filterSubmitLoading = true;
      await this.loadTableData({
        resetPage: true
      });
      this.filterSubmitLoading = false;
    },
    /**
      * 重置表单查询
      */
    async resetFilterSubmit() {
      this.filterSubmitLoading = true;
      await this.loadTableData({
        resetPage: true,
        resetFilter: true
      });
      this.filterSubmitLoading = false;
    },

    /**
     * 处理表格排序、分页信息改变
     */
    handleTableChange(tablePagination, filters, sorter) {
      this.tableSort = sorter.order == null ? this.config?.sorter?.defaultSort : (sorter.order === 'ascend' ? '' : '-') + sorter.columnKey;
      this.tablePagination = {
        ...this.tablePagination,
        current: tablePagination.current,
        pageSize: tablePagination.pageSize
      };
      this.loadTableData();
    },

    statisticsChange(e) {
      this.handleFilterSubmit()
    },

    /**
     * 加载表格数据
     */
    async loadTableData(options) {
      options = {
        resetPage: false,
        ...options
      };

      this.tablePagination = {
        ...this.tablePagination,
        current: options.resetPage ? 1 : this.tablePagination.current
      };
      if (this.config.dataUrl != null) {
        this.tableLoading = true;
        try {
          const { rows, data, attachData } = await this.$http.get(this.config.dataUrl, {
            ...this.getFilterForm(options),
            sort: this.tableSort,
            page: this.tablePagination.current,
            size: this.tablePagination.pageSize,
            quickCheck: this.config.listFilter ? this.listFilterKey[this.config.listFilter.key] : null
          });

          this.tableData = this.config.tree != null ? tree(data, {
            parent: this.config.tree
          }) : data;
          this.tableData.map((item) => {
            this.config.tree != null ? item.key = item.id : ''
          })

          // 合并表格系列
          if (this.config.rowSpan) {
            const result = [];
            this.tableData && this.tableData.forEach(item => {
              const keys = Object.keys(item);
              item.detailList.forEach((subItem, index) => {
                const newObj = {};
                keys.forEach(key => {
                  newObj[key] = item[key];
                });
                for (const key in subItem) {
                  newObj[`detailList_${key}`] = subItem[key];
                }
                newObj.rowSpan = index === 0 ? item.detailList.length : 0;
                result.push(newObj);
              });
            });
            this.tableData = result
          }

          this.tablePagination = { ...this.tablePagination, total: rows };
          this.attachData = attachData;
        } catch ({ message }) {
          this.$message.error(message);
        }
        this.tableLoading = false;
        return;
      }

      // 静态数据
      if (this.config.data != null) {
        this.tableData = this.config.data;
      }
    },

    /**
     * 处理表格行选择
     */
    handleTableRowSelect(selectedRowKeys, selectedRows) {
      this.tableSelectedRowKeys = selectedRowKeys;
    },

    /**
     * 获取过滤表单
     */
    getFilterForm(options) {
      if (!options.resetFilter) {
        return this.filterControls.reduce((prev, control) => {
          if (control.type === 'numberRange') {
            prev[control.start] = this.filterForm[control.start];
            prev[control.end] = this.filterForm[control.end];
          }
          const value = this.filterForm[control.key];
          if (value != null) {
            if (control.type === 'dateRange') {
              prev[`${control.key}OnOrAfter`] = dayjs(value[0]).format('YYYY-MM-DD') + ' 00:00:00';
              prev[`${control.key}OnOrBefore`] = dayjs(value[1]).format('YYYY-MM-DD') + ' 23:59:59';
            } else if (control.type === 'date') {
              if (control.config.picker == 'month') {
                prev[control.key] = dayjs(value).format('YYYY-MM');
              } else {
                prev[control.key] = dayjs(value).format('YYYY-MM-DD') + ' 00:00:00';
              }
            } else if (control.type === 'datetime') {
              prev[control.key] = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
            } else {
              prev[control.key] = value;
            }
          }
          return prev;
        }, {});
      } else {
        return this.filterControls.reduce((prev, control) => {
          if (control.resetDisabled) {
            if (control.type === 'numberRange') {
              prev[control.start] = this.filterForm[control.start];
              prev[control.end] = this.filterForm[control.end];
            }
            const value = this.filterForm[control.key];
            if (value != null) {
              if (control.type === 'dateRange') {
                prev[`${control.key}OnOrAfter`] = dayjs(value[0]).format('YYYY-MM-DD') + ' 00:00:00';
                prev[`${control.key}OnOrBefore`] = dayjs(value[1]).format('YYYY-MM-DD') + ' 23:59:59';
              } else if (control.type === 'date') {
                if (control.config.picker == 'month') {
                  if (control.config.initialValue == '上月') {
                    const today = dayjs();
                    const lastMonth = today.subtract(1, 'month');
                    prev[control.key] = dayjs(lastMonth).format('YYYY-MM')
                    this.filterForm[control.key] = lastMonth
                  } else {
                    prev[control.key] = dayjs(value).format('YYYY-MM');
                  }
                } else {
                  prev[control.key] = dayjs(value).format('YYYY-MM-DD') + ' 00:00:00';
                }
              } else if (control.type === 'datetime') {
                prev[control.key] = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
              } else {
                prev[control.key] = value;
              }
            }
          } else {
            // if (typeof (this.filterForm[control.key]) === 'object') {
            //   this.filterForm[control.key] = [];
            // } else {
            //   this.filterForm[control.key] = null;
            // }
            this.filterForm = {};
          }

          return prev;
        }, {});
      }
    },

    /**
     * 指定列是否可排序
     */
    sortable(column) {
      return this.sortableColumns.has(column.key);
    },

    /**
     * 指定列默认排序方向
     */
    defaultSortOrder(column) {
      return this.tableSort?.replace(/^-/, '') !== column.key ? null
        : this.tableSort.startsWith('-') ? 'descend' : 'ascend';
    },

    /**
     * 获取操作组件名
     */
    getActionComponent(action) {
      return action.type + '-action';
    },
    //年月日
    getYMDMoment(value) {
      if (value) {
        return dayjs(value).format('YYYY-MM-DD')
      } else {
        return '无'
      }
    },
    //年月日 时分秒
    getYMDHMSMoment(value) {
      if (value) {
        return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return '无'
      }
    },
    //列表多查看人逗号分隔
    getArrStrComma(val, itemArr) {
      if (itemArr) {
        let ckey = val.split('.')
        let allItem = ''
        itemArr.map((item, index) => {
          let comma = itemArr.length <= index + 1 ? '' : '，'
          const value = ckey.reduce((obj, key) => obj[key], item);
          allItem += value + comma
        })
        return allItem
      }
    },
    //得到嵌套对象
    getArrSpliComma(val, allItem) {
      let ckey = val.split('.')
      for (let i = 0; i < ckey.length; i++) {
        if (allItem[ckey[0]] !== null) {
          allItem = allItem[ckey[i]];
        } else {
          allItem = ''
        }
      }
      return allItem
    },

    disabledDate(current) {
      return current && current > dayjs().endOf('day');
    },

    //是否跳转
    jump(column, row) {
      this.$router.push(column.config.path + '?id=' + row.id + '&other=' + row[column.config.query])
    },

    showInfo(row, key) {
      this.config.columns.map(item => {
        if (item.key === key) {
          if (item.config.table) {
            if (item.config.needUrl) {
              this.modal.table = item.config.table;
              this.modal.url = item.config.url;
              this.modal.needForm = item.config.needForm;
              this.modal.title = item.config.title;
              this.modal.filter = item.config.filter;
              this.modal.columns = item.config.columns;
              this.modal.row = row;
              this.getModalTable();
            } else {
              this.modal.table = item.config.table;
              this.modal.title = item.config.title;
              this.modal.columns = item.config.columns;
              this.modal.row = row;
              this.modal.data = row.invitations;
            }

          } else {
            this.modal.title = item.config.title;
            this.modal.content = item.config.content(row);
          }
        }
      })
      this.openInfoModal = true;
    },

    async getModalTable() {
      let filter = {};
      filter[this.modal.filter] = this.modal.row[this.modal.filter]
      let options = {
        resetPage: false,
      };
      let filterForms = null;
      if (this.modal.needForm) {
        filterForms = this.getFilterForm(options)
      }

      try {
        const res = await this.$http.get(this.modal.url, {
          ...filterForms,
          ...filter
        });
        this.modal.data = res.data;
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    closeInfoModal() {
      this.openInfoModal = false;
      this.modal = {
        title: '',
        content: '',
        table: false,
        data: [],
        url: '',
        needForm: false,
        filter: '',
        row: {},
        columns: []
      }
    }
  }
}
</script>
