import channelEmployeeData from "./channelEmployeeData";
import marketEmployeeData from "./marketEmployeeData";
import customerServiceData from "./customerServiceData";
import customerSourceData from "./customerSourceData";
import dataGlance from "./dataGlance";
import institutionalData from "./institutionalData";
export default [
  channelEmployeeData,
  marketEmployeeData,
  dataGlance,
  institutionalData,
  customerServiceData,
  customerSourceData
];
