//import Vue from 'vue';
import { createApp } from 'vue'
import numeral from "numeral";
import hljs from 'highlight.js'
import "highlight.js/scss/atom-one-dark.scss";
import "nprogress/nprogress.css";
//import Icon from '@ant-design/icons-vue';
import * as Icons from '@ant-design/icons-vue'
// import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import { api as viewerApi } from "v-viewer"
import App from './App.vue';
import router from './router';
// import store from './store';
import * as http from './utils/http';
import * as echarts from 'echarts'
const app = createApp(App)
import {
  Image,
  Cascader,
  Form,
  Avatar,
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Popconfirm,
  Radio,
  Result,
  Row,
  Select,
  Skeleton,
  Collapse,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Tree,
  TreeSelect,
  List,
  Tooltip,
  Comment,
  Popover,
  Transfer,
  Modal,
  Upload, Checkbox, Steps, Card, Statistic, Empty, Rate
} from 'ant-design-vue';
import "./main.scss";

app.config.productionTip = false;
app.use(Image);
app.use(Statistic);
app.use(Card);
app.use(Cascader);
app.use(Form);
app.use(Button);
app.use(Drawer);
app.use(ConfigProvider);
app.use(Layout);
app.use(Dropdown);
app.use(Space);
app.use(List)
app.use(Menu);
app.use(Comment)
app.use(Tooltip);
app.use(Breadcrumb);
app.use(Avatar);
app.use(Collapse)
app.use(Input);
app.use(Table);
app.use(InputNumber);
app.use(DatePicker);
app.use(Select);
app.use(Divider);
app.use(Skeleton);
app.use(Popconfirm);
app.use(Tag);
app.use(Result);
app.use(Tabs);
app.use(Tree);
app.use(Popover)
app.use(Row);
app.use(Col);
app.use(Radio);
app.use(Switch);
app.use(Upload);
app.use(Modal)
app.use(Transfer)
app.use(TreeSelect);
app.use(Checkbox);
app.use(Steps);
app.use(Empty);
app.use(Rate);

//引入图片浏览插件
// Viewer.setDefaults({
//   Options: {
//     "inline": true,
//     "button": true,
//     "navbar": true,
//     "title": true,
//     "toolbar": true,
//     "tooltip": true,
//     "movable": true,
//     "zoomable": true,
//     "rotatable": true,
//     "scalable": true,
//     "transition": true,
//     "fullscreen": true,
//     "keyboard": true,
//     "url": "data-source"
//   }
// });

/* prototype */
app.config.globalProperties.$message = message;
app.config.globalProperties.$http = http;
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$viewerApi = viewerApi;

/* filter */
// Vue.filter('formatDate', formatDate);
// Vue.filter('formatPercent', (value) => {
//   return (value * 100).toFixed(2) + '%';
// });
// Vue.filter('filePath', (value) => {
//   return value != null ? String(value).startsWith('http') ? value : `http://test-jike-img.oss-cn-hangzhou.aliyuncs.com/${value}` : '';
// });
// Vue.filter('formatCurrency', (value) => {
//   return numeral(value).format('0,0.00');
// });
// Vue.filter('exerciseType', (value) => {
//   switch (value) {
//     case 'SINGLE_CHOICE':
//       return '单选题';
//     case 'MULTIPLE_CHOICE':
//       return '多选题';
//     case 'JUDGE':
//       return '判断题';
//     case 'SHORT_ANSWER':
//       return '简答题';
//     default:
//       return '';
//   }
// })


/* directive */
if (process.env.NODE_ENV === 'development') {
  app.directive('highlightjs', {
    deep: true,
    bind: function (el, binding) {
      const targets = el.querySelectorAll('code')
      targets.forEach((target) => {
        if (binding.value) {
          target.innerHTML = binding.value
        }
        hljs.highlightBlock(target)
      })
    },
    componentUpdated: function (el, binding) {
      const targets = el.querySelectorAll('code')
      targets.forEach((target) => {
        if (binding.value) {
          target.innerHTML = binding.value
          hljs.highlightBlock(target)
        }
      });
    }
  });
}

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// 注册组件
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key])
})
// 添加到全局
app.config.globalProperties.$icons = Icons

app.use(router)
// app.use(store)
app.mount('#app')
// nextTick(() => {
//   // 配置全局对象
//   app.config.globalProperties.$icons = Icons
//   // Antd 注入全部图标（这样注入之后，就可以全局直接使用 icon 组件，不需要每个页面去引入了）
//   for (const key in Icons) { app.component(key, Icons[key]) }
// })

//createApp(App).use(router).use(store).mount('#app')

app.config.globalProperties.$formatPercent = (value) => {
  return (value * 100).toFixed(2) + '%';
}
app.config.globalProperties.$formatCurrency = (value) => {
  return numeral(value).format('0,0.00') + "元";
}

app.config.globalProperties.$formatCurrencyNumber = (value) => {
  return numeral(value).format('0,0.00');
}

app.config.globalProperties.$isMobile = document.documentElement.clientWidth <= 768;